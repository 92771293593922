<template>
    <!-- 发送量限制-菜单页 -->
    <div id="index_system_control_div">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">系统限制</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <template v-for="item in list">
                <el-row :key="item.name" @click.native="router_to(item.link_url)" class="font_size2 cursor"
                       style="border-bottom: 1px solid #cccccc;padding:15px 0;">
                    <el-col :span="19"><span>{{item.name}}</span></el-col>
                    <el-col :span="5" style="text-align:right;" class="font_size1"><i class="el-icon-arrow-right"></i></el-col>
                </el-row>
            </template>
        </el-row>
        
    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

export default {
    
    data(){
        return{
            list:[{
                name:"客户发送量限制",
                link_url:"/cpid_send_num_limit_list",//路由地址
            },{
                name:"通道发送量限制",
                link_url:"/channel_send_num_limit_list",//路由地址
            }]
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
       
    },
    //方法
    methods: {
        //跳转链接
        router_to(link_url){
            //跳转链接
            API.router_to(link_url);
        },

        //返回
        goBack() {
            API.menu();////跳转到主页面
        }
    }
};

</script>

<style scoped>

</style>